import * as React from 'react'
import styled, { css } from 'styled-components'

import { changeNavColor, getRemValue } from '../styles/utils'

import MainNavigation, { NavItem } from './MainNavigation'

import {
  grid,
  colors,
  dimensions,
  media,
  onEvent,
  Button,
  CloseIcon,
  CodeControlIcon,
  MenuIcon,
} from 'cc-ui-components'
import Link from './common/Link'
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl'
import { graphql, StaticQuery } from 'gatsby'
import * as _ from 'lodash'

let defaultNavItems = [
  { text: 'Find Top Talent ', link: '/find-top-talent' },
  { text: 'Start a Project', link: '/start-project' },
  { text: 'Clients', link: '/clients' },
  { text: 'Community', link: '/community' },
  { text: 'About us', link: '/about-us' },
  { text: 'Blog', link: '/blog' },
  { text: 'Events', link: 'https://freelancetechacademy.codecontrol.io/' }
]

interface HeaderProps extends InjectedIntlProps {
  dark?: boolean
  light?: boolean
  hideNav?: boolean
  hideMobileNav?: boolean
  showMobileCTA?: boolean
  navItems?: NavItem[]
}

interface HeaderState {
  mobileOpen: boolean
  reduced: boolean
}

interface ColorProps {
  dark?: boolean
  reduced?: boolean
  light?: boolean
  hideNav?: boolean
}

const Wrapper = styled.header`
  padding: 42px 0;
  transform: translateZ(0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: ${colors.bodyBackground};
  transition: padding 0.5s;
  color: ${(props: ColorProps) =>
    props.dark ? colors.inverseText : colors.text};
  background: ${(props: ColorProps) =>
    props.dark ? colors.darkBackground : colors.inverseText};
  ${(props: ColorProps) =>
    props.light ? `background: ${colors.lightBackground};` : ''} svg {
    max-height: 100px;
    max-width: 100px;
    transition: all 0.5s;
    margin-top: -20px;
  }
  &.white {
    background: ${colors.bodyBackground};
    color: ${colors.text};
    a {
      color: ${colors.text};
      &:after {
        border-bottom-color: ${colors.text};
      }
    }
    path {
      fill: ${colors.text};
    }
    nav ~ div div div {
      background: ${colors.darkBackground};
      color: ${colors.inverseText};
    }
  }
  nav ~ div div div {
    background: ${(props: ColorProps) =>
      props.dark ? colors.bodyBackground : colors.darkBackground} !important;
    border: 1px solid ${colors.text};
    color: ${(props: ColorProps) =>
      props.dark ? colors.text : colors.inverseText} !important;
  }
  &.black {
    background: ${colors.darkBackground};
    a {
      color: ${colors.inverseText};
      &:after {
        border-bottom-color: ${colors.inverseText};
      }
    }
    path {
      fill: ${colors.inverseText};
    }
    nav ~ div div div {
      background: ${colors.bodyBackground};
      color: ${colors.text};
    }
  }
  &.light {
    background: ${colors.lightBackground};
    a {
      color: ${colors.text};
      &:after {
        border-bottom-color: ${colors.text};
      }
    }
    path {
      fill: ${colors.text};
    }
    nav ~ div div div {
      background: ${colors.darkBackground};
      color: ${colors.inverseText};
    }
  }
  ${props =>
    !props.showMobileCTA
      ? css`
          &.transparent {
            background: transparent;
          }
        `
      : css`
          ${media.lg`
        &.transparent {
          background: transparent;
        }
      `};
        `};
  ${props =>
    props.reduced &&
    css`
      padding-top: 10px;
      padding-bottom: 10px;
      svg {
        max-height: 35px;
        max-width: 35px;
        margin-top: 0;
      }
      ${Actions} {
        margin-top: 0;
      }
      ${NavWrapper} {
        margin-top: 0;
      }
      ${HomepageLink} {
        margin-top: 6px;
        ${media.tablet`
          margin-top: 2px;
        `}
      }
      ${media.tablet`
        svg {
          max-height: 25px !important;
          max-width: 25px !important;
          &#menu-icon {
            margin-top: -1.5px !important;
          }
        }
        ${Actions} {
          transform: scale(0.85);
          margin-top: -11px;
          margin-right: -24px;
        }
        ${HeaderInner} {
          padding-bottom: 11px;
      `};
    `};
  ${media.tablet`
    padding: 0;
    background: ${props =>
      props.showMobileCTA ? 'inherit' : 'transparent !important'};

    svg {
      max-height: 45px;
      max-width: 45px;
      margin-top: -5px;
      &#menu-icon {
        margin-top: 9px;
        margin-right: 2px;
      }
    }
  `};
`

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${getRemValue(grid.spacing)};
  max-width: ${getRemValue(dimensions.headerWidth + grid.spacing * 2)};
  margin: 0 auto;
  ${media.tablet`
    padding: 20px 16px 20px 15px;
  `};
`

const HomepageLink = styled(Link)`
  ${onEvent`
    text-decoration: none;
  `};
`

const Logo = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  svg {
    width: 100%;
    height: auto;

    path {
      fill: ${(props: ColorProps) =>
        props.dark ? colors.inverseText : colors.text};
    }
  }
`

const MobileLogo = styled(Logo)`
  top: 0;
`

const NavWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -7px;
`

const MobileNavWrapper = styled.div`
  color: black;
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  top: 0;
  left: 0;
  background: ${colors.bodyBackground};
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ul {
    flex-direction: column;
    align-items: center;
    margin-bottom: 26px;
    height: 100%;
    justify-content: center;
  }
  nav {
    height: 50%;
  }
  a {
    font-size: 15px;
    color: ${colors.text} !important;
    &:after {
      border-bottom-color: ${colors.text} !important;
    }
  }
  svg {
    background: ${colors.bodyBackground} !important;
  }
  path {
    fill: ${colors.text} !important;
  }
  nav ~ div div div {
    margin-top: 4px;
    background: ${colors.bodyBackground} !important;
    border: 1px solid ${colors.text};
    color: ${colors.text} !important;
    min-width: 75vw;
    padding: 18px 0 17px;
    height: auto;
    p {
      font-size: 15px;
    }
  }
  #close-icon {
    margin-top: 14px !important;
  }
  & > div:first-of-type {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`

const TopContainer = styled.div`
  margin: 20px 15px 38px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CloseIconWrapper = styled.div`
  cursor: pointer;
  padding: 5px;
  margin-top: 6px;
`

const Actions = styled.div`
  display: flex;
  padding-left: 84px;
  margin-right: -0.625em;
  margin-top: -9px;
  transition: all 0.5s;
  ${media.tablet`
    display: ${props => (props.showMobileCTA ? 'flex' : 'none')};
    padding: 0;
    padding-right: ${props => (props.hideMobileNav ? '8px' : '48px')};
    margin-top: -2px;
    width: 100%;
    justify-content: flex-end;
  `};
`

const ActionItem = styled.div``

const IconContainer = styled.div`
  height: 100%;
  align-items: baseline;
  position: absolute;
  right: 16px;
  svg {
    transform: scale(1.3);
  }
  path {
    fill: ${(props: ColorProps) =>
      props.dark ? colors.inverseText : colors.text};
  }
`

const MediaWrapper = styled.div`
  flex: 1;
  & > div:first-child {
    display: none;
  }
  ${media.tablet`
    & > div:last-child {
      display: none;
    }
    & > div:first-child {
      display: flex;
    }
  `};
`

class Header extends React.PureComponent<HeaderProps, HeaderState> {
  public wrapper: any

  constructor(props: HeaderProps) {
    super(props)
    this.state = {
      mobileOpen: false,
      reduced: false,
    }
    this.wrapper = React.createRef()
  }

  public calculateScroll = () => {
    const lastKnownScrollPosition = window.scrollY
    const shouldBeReduced = lastKnownScrollPosition > 50
    if (this.state.reduced !== shouldBeReduced) {
      if (this.wrapper && this.wrapper.current) {
        const { className } = this.wrapper.current
        if (className.includes('transparent')) {
          let classColor = 'white'
          if (className.includes('black')) {
            classColor = 'black'
          } else if (className.includes('light')) {
            classColor = 'light'
          }
          changeNavColor(`${classColor} transparent`)
        }
      }
      this.setState({
        reduced: shouldBeReduced,
      })
    }
  }

  public componentDidMount() {
    window.addEventListener('scroll', this.calculateScroll)
  }

  public manageMobileNav = () => {
    const { reduced, mobileOpen } = this.state
    this.setState({
      mobileOpen: !this.state.mobileOpen,
      reduced: !mobileOpen ? false : reduced,
    })
    if (mobileOpen) {
      this.calculateScroll()
    }
    !mobileOpen
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'scroll')
  }

  public renderDesktopNav() {
    const { dark, navItems, hideNav } = this.props

    return (
      !hideNav && (
        <NavWrapper>
          <MainNavigation dark={dark} items={navItems || defaultNavItems} />
        </NavWrapper>
      )
    )
  }

  public renderMobileNav() {
    const { dark, navItems, intl, hideMobileNav } = this.props
    const { mobileOpen } = this.state

    if (hideMobileNav) {
      return
    }

    if (!mobileOpen) {
      return (
        <IconContainer dark={dark} onClick={this.manageMobileNav}>
          <MenuIcon />
        </IconContainer>
      )
    }
    const isDark = dark && !mobileOpen
    return (
      <MobileNavWrapper>
        <TopContainer>
          <HomepageLink to="/" onClick={this.manageMobileNav}>
            <MobileLogo dark={dark}>
              <CodeControlIcon />
            </MobileLogo>
          </HomepageLink>
          <CloseIconWrapper onClick={this.manageMobileNav}>
            <CloseIcon />
          </CloseIconWrapper>
        </TopContainer>
        <MainNavigation
          dark={isDark}
          items={navItems || defaultNavItems}
          handleClick={this.manageMobileNav}
        />
      </MobileNavWrapper>
    )
  }

  public render() {
    const { dark, light, intl, ...rest } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            allContentfulModuleNavigation {
              edges {
                node {
                  title
                  node_locale
                  navigationElements {
                    ...navigationElements
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const navigation: any =
            data.allContentfulModuleNavigation.edges.filter(
              (edge: any) =>
                edge.node.title === 'Main Navigation' &&
                edge.node.node_locale.startsWith(intl.locale)
            )[0].node || null
          if (navigation) {
            defaultNavItems = navigation.navigationElements.map(
              (element: any) => {
                const path =
                  element.targetPath || _.get(element, 'targetPage.slug')
                return {
                  text: element.title,
                  link:
                    element.href ||
                    `/${intl.locale}/${path}`.replace('//', '/'),
                }
              }
            )
          }

          return (
            <Wrapper
              reduced={this.state.reduced}
              dark={dark}
              {...rest}
              id={'nav-wrapper'}
              light={light}
              ref={this.wrapper}
            >
              <HeaderInner>
                <HomepageLink to={`/${intl.locale}/home`}>
                  <Logo dark={dark}>
                    <CodeControlIcon />
                  </Logo>
                </HomepageLink>
                <MediaWrapper>
                  {this.renderMobileNav()}
                  {this.renderDesktopNav()}
                </MediaWrapper>
                <Actions {...this.props}>
                  <ActionItem>
                    <Link to={intl.formatMessage({ id: 'path.get-in-touch' })}>
                      <FormattedMessage id="header.cta">
                        {txt => (
                          <Button
                            medium
                            key="action1"
                            flat
                            text={txt.toString()}
                          />
                        )}
                      </FormattedMessage>
                    </Link>
                  </ActionItem>
                </Actions>
              </HeaderInner>
            </Wrapper>
          )
        }}
      />
    )
  }
}

export default injectIntl(Header)
