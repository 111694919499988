import * as React from 'react'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { colors, fontSizes, onEvent, media } from 'cc-ui-components'
import { Location } from '@reach/router'

export interface NavItem {
  text: string
  link: string
}

interface LinkProps {
  dark: number
  active: number
}

interface MainNavigationProps {
  dark?: boolean
  items: NavItem[]
  handleClick?: () => void
}

const Nav = styled.nav`
  font-size: ${fontSizes.sm};
  margin-top: 1.35em;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul {
    display: flex;
    margin: -1.25em -1.5em;
    ${media.sm`
      display: block;
      text-align: center;
    `};
  }
`

const linkActiveStyle = css`
  &::after {
    opacity: 1;
    width: 85%;
  }
`

const Link = styled(GatsbyLink)`
  color: ${(props: LinkProps) =>
    props.dark ? colors.inverseText : colors.text};
  font-weight: 300;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  padding: 1.25em 1.5em;
  line-height: 1;
  display: inline-block;
  position: relative;
  user-select: none;

  &::after {
    display: block;
    content: ' ';
    position: absolute;
    bottom: 0.417em;
    left: 50%;
    opacity: 0;
    width: 0;
    transform: translate(-50%, 0);
    border-bottom: 1px solid
      ${(props: LinkProps) => (props.dark ? colors.inverseText : colors.text)};
    /* TODO: talk about transitions with designer */
    transition: width 170ms ease, opacity 170ms ease;
  }

  ${onEvent`
    text-decoration: none;
    ${linkActiveStyle}
  `};

  ${({ active }: LinkProps) => active && linkActiveStyle};
`

const ExternalLink = styled('a')`
  color: ${(props: LinkProps) =>
    props.dark ? colors.inverseText : colors.text};
  font-weight: 300;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  padding: 1.25em 1.5em;
  line-height: 1;
  display: inline-block;
  position: relative;
  user-select: none;

  &::after {
    display: block;
    content: ' ';
    position: absolute;
    bottom: 0.417em;
    left: 50%;
    opacity: 0;
    width: 0;
    transform: translate(-50%, 0);
    border-bottom: 1px solid
      ${(props: LinkProps) => (props.dark ? colors.inverseText : colors.text)};
    /* TODO: talk about transitions with designer */
    transition: width 170ms ease, opacity 170ms ease;
  }

  ${onEvent`
    text-decoration: none;
    ${linkActiveStyle}
  `};
`

const MainNavigation: React.SFC<MainNavigationProps> = ({
  dark,
  items,
  handleClick,
}: MainNavigationProps) => (
  <Nav>
    <ul>
      {items.map((item, index) => (
        <li key={`mainNavItem${index}`}>
          <Location>
            {({ location }) =>
              item.link.includes('://') ? (
                <ExternalLink dark={dark ? 1 : 0} active={0} href={item.link}>
                  {item.text}
                </ExternalLink>
              ) : (
                <Link
                  dark={dark ? 1 : 0}
                  to={item.link}
                  onClick={handleClick}
                  active={location.pathname.includes(item.link) ? 1 : 0}
                >
                  {item.text}
                </Link>
              )
            }
          </Location>
        </li>
      ))}
    </ul>
  </Nav>
)

export default MainNavigation
